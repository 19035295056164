<template>
  <div>
    <a-row type="flex" justify="center" style="margin-top: -200px">
      <a-col :span="23" :lg="16">
        <a-row
          style="
            background-color: #ffffff;
            box-shadow: 0px 0px 17px -8px rgba(14, 155, 210, 20);
            border-radius: 8px;
          "
          :style="{ padding: fullWidth > 992 ? '20px 30px' : '20px 10px' }"
        >
          <a-col :span="24">
            <el-page-header
              style="
                width: 100%;
                height: 50px;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 10px;
              "
              @back="backLegalHome"
              content="成绩查询"
            >
            </el-page-header>
            <template v-if="empty">
              <a-row
                type="flex"
                justify="center"
                style="margin-bottom: 10px; margin-top: 10px; min-height: 400px"
              >
                <el-empty :description="description"></el-empty>
              </a-row>
            </template>
            <template v-else>
              <a-row
                :gutter="24"
                style="min-height: 500px"
                v-loading="loading"
                element-loading-text="加载中"
                element-loading-spinner="el-icon-loading"
                element-loading-background="rgba(0, 0, 0, 0.2)"
              >
                <!-- 主体内容 -->
                <a-col :span="24" style="margin-bottom: 20px">
                  <div style="padding: 10px; border-radius: 12px; min-height: 350px">
                    <div
                      v-if="totalScore == '' && flag === false"
                      style="
                        border-radius: 12px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                      "
                    >
                      <input
                        type="text"
                        class="search-input"
                        v-model="idCard"
                        placeholder="请输入身份证号"
                      />
                      <input
                        type="text"
                        class="search-input"
                        v-model="mobile"
                        mobile
                        placeholder="请输入手机号"
                      />
                    </div>
                    <div
                      v-else
                      style="
                        border-radius: 12px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;
                      "
                    >
                      <span>您的成绩为</span>
                      <div style="font-size: 30px; font-weight: bold; margin-top: 20px">
                        {{ totalScore }}
                      </div>
                    </div>
                    <div
                      style="width: 340px; margin: 40px auto 0 auto; padding-bottom: 30px"
                    >
                      <a-button class="submit-btn" @click="showGrades()"
                        >{{ totalScore && flag ? "再次查询" : "查询" }}
                      </a-button>
                    </div>

                    <div class="top-three">
                      <!-- 亚军 -->
                      <div class="second-person">
                        <a-col :span="12" :lg="6" style="padding: 0">
                          <div class="second-box">
                            <!-- 图片 -->
                            <div class="second-img">
                              <img
                                src="/images/runner-up.png"
                                alt=""
                                style="height: 100%; margin-top: -60px"
                              />
                            </div>
                            <div v-if="topThreeList[1]">
                              <div
                                class="u-line-2"
                                style="font-size: 18px; color: #333; font-weight: 700"
                              >
                                {{ topThreeList[1].userName }}
                              </div>
                              <div class="second-schoolName">
                                {{ topThreeList[1].schoolName }}
                              </div>
                              <div class="second-total-score">
                                {{ topThreeList[1].totalGiveScore }} '
                              </div>
                              <div class="second-total-time">
                                {{ topThreeList[1].answerStartToEndTime | timeChanges }}
                              </div>
                            </div>
                            <div class="no-data" v-else>暂无数据</div>
                          </div>
                        </a-col>
                      </div>
                      <!-- 冠军 -->
                      <div class="first-person">
                        <a-col :span="12" :lg="6" style="height: 88%; padding: 0">
                          <div class="first-box">
                            <!-- 图片 -->
                            <div class="first-img">
                              <img
                                src="/images/champion.png"
                                alt=""
                                style="height: 100%; margin-top: -60px"
                              />
                            </div>
                            <div
                              class="u-line-2"
                              style="font-size: 18px; color: #333; font-weight: 700"
                            >
                              {{ topThreeList[0].userName }}
                            </div>
                            <div class="first-schoolName">
                              {{
                                topThreeList[0].schoolName
                                  ? topThreeList[0].schoolName
                                  : "无"
                              }}
                            </div>
                            <div class="first-total-score">
                              {{ topThreeList[0].totalGiveScore }} '
                            </div>
                            <div
                              class="u-line-2"
                              style="font-size: 14px; color: #a5a7b2; font-weight: 400"
                            >
                              {{ topThreeList[0].answerStartToEndTime | timeChanges }}
                            </div>
                          </div>
                        </a-col>
                      </div>
                      <!-- 季军 -->
                      <div class="third-person">
                        <a-col :span="12" :lg="6" style="padding: 0">
                          <div class="third-box">
                            <!-- 图片 -->
                            <div class="third-img">
                              <img
                                src="/images/Third-place.png"
                                alt=""
                                style="height: 100%; margin-top: -60px"
                              />
                            </div>
                            <div v-if="topThreeList[2]">
                              <div
                                class="u-line-2"
                                style="font-size: 18px; color: #333; font-weight: 700"
                              >
                                <span>{{ topThreeList[2].userName }}</span>
                              </div>
                              <div class="third-schoolName">
                                <span>{{ topThreeList[2].schoolName }}</span>
                              </div>
                              <div class="third-total-score">
                                <span>{{ topThreeList[2].totalGiveScore }} '</span>
                              </div>
                              <div
                                class="u-line-2"
                                style="font-size: 14px; color: #a5a7b2; font-weight: 400"
                              >
                                <span>{{
                                  topThreeList[2].answerStartToEndTime | timeChanges
                                }}</span>
                              </div>
                            </div>
                            <div v-else>
                              <span class="no-data">暂无数据</span>
                            </div>
                          </div>
                        </a-col>
                      </div>
                    </div>
                  </div>
                  <!-- 前三名以外 -->
                  <div
                    class="other-person"
                    v-for="(item, index) in rankingList"
                    :key="index"
                  >
                    <div class="other-box">
                      <!-- 图片 -->
                      <div class="other-img">
                        <div class="other-img-box">
                          <span class="other-userName">{{ index + 4 }}.</span>
                          {{ item.userName }}
                        </div>
                        <div class="other-schoolName">
                          {{ item.schoolName ? item.schoolName : "无" }}
                        </div>
                      </div>
                      <div style="display: flex; flex: none">
                        <div class="other-endTime">
                          {{ item.answerStartToEndTime | timeChanges }}
                        </div>
                        <div class="other-total-score">{{ item.totalGiveScore }} '</div>
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </template>
          </a-col>
        </a-row>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { isMobile, isCardID } from "../../utils/index.js";
export default {
  created() {
    this.getRankingList();
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  computed: {
    ...mapState(["fullWidth"]),
  },
  data() {
    return {
      loading: true,
      empty: false,
      rankingList: [],
      topThreeList: [],
      idCard: "",
      mobile: "",
      totalScore: 0,
      flag: false,
    };
  },
  filters: {
    // 转换答题时间格式
    timeChanges(result) {
      var h =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      var m =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      var s =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);
      if (h == 0 && m != 0) {
        return (result = m + "分" + s + "秒");
      } else if (h == 0 && m == 0) {
        return (result = s + "秒");
      } else {
        return (result = h + "时" + m + "分" + s + "秒");
      }
    },
  },
  methods: {
    // 查询成绩
    showGrades() {
      if (!isCardID(this.idCard)) {
        this.$elementMessage({
          message: "请输入正确的身份证号",
          type: "error",
        });
        return;
      }
      if (!isMobile(this.mobile)) {
        this.$elementMessage({
          message: "请输入正确的手机号",
          type: "error",
        });
        return;
      }
      this.$HTTP
        .httpToken({
          url: "/wst/testPaper/selectScore",
          method: "post",
          data: {
            topicId: this.$route.query.topicId,
            idCardNo: this.idCard,
            mobile: this.mobile,
          },
        })
        .then((res) => {
          console.log("排名", res);
          if (res.code == "0" && res.data) {
            this.flag = !this.flag;
            this.totalScore = res.data.totalGiveScore;
            if (!this.flag) {
              this.totalScore = "";
              this.idCard = "";
            }
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch((e) => {});
    },
    // 获取排名列表
    getRankingList() {
      this.$HTTP
        .httpToken({
          url: "/wst/testPaper/rankList",
          method: "post",
          data: {
            topicId: this.$route.query.topicId,
          },
        })
        .then((res) => {
          console.log("排名", res);
          this.loading = false;
          if (res.code == "0" && res.data && res.data.length > 0) {
            this.empty = false;
            this.rankingList = res.data;
            // 判断答题人数
            // 如果答题人数大于三人
            if (this.rankingList.length > 3) {
              this.topThreeList = this.rankingList.filter((v, i) => {
                return i <= 2;
              });
              this.rankingList = this.rankingList.slice(3);
            } else {
              // 如果不足三人
              this.topThreeList = this.rankingList;
              this.rankingList = [];
            }
          } else {
            this.empty = true;
            this.description = "暂无数据";
          }
        })
        .catch((e) => {
          this.loading = false;
          this.empty = true;
          this.description = e;
        });
    },
    backLegalHome() {
      this.$router.back();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .u-line-2 {
  overflow: visible !important;
}

.ant-col-12 {
  width: 130%;
}

.ranking-box {
  margin: 0 25px;
  width: 165px;
}

.search-input {
  width: 60%;
  height: 50px;
  font-size: 17px;
  text-align: center;
  border-radius: 25px;
  background: #f6f8f9;
  border: 1px solid #eee;
  margin-bottom: 20px;
  outline: none;
}

.submit-btn {
  border-radius: 25px;
  background: linear-gradient(90deg, #51d2be, #0090d7);
  font-size: 15px;
  color: #fefefe;
  font-weight: bold;
  height: 50px;
  width: 340px;
  border: none;
}

.top-three {
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: 80px;

  .second-person {
    margin: 0 25px;
    width: 165px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .second-box {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
      border-radius: 8px;
      margin-bottom: 24px;
      cursor: pointer;
      margin-top: 50px;

      .second-img {
        overflow: visible !important;
        font-size: 14px;
        color: #101010;
        font-weight: 400;
        margin-bottom: 8px;
        height: 120px;
        position: relative;
      }

      .second-schoolName {
        overflow: visible !important;
        font-size: 14px;
        color: #a5a7b2;
        font-weight: 400;
        height: 36px;
        line-height: 36px;
      }

      .second-total-score {
        overflow: visible !important;
        font-size: 14px;
        color: #333333;
        font-weight: 600;
        font-family: SourceHanSansCN-Medium;
      }

      .second-total-time {
        font-size: 14px;
        color: #a5a7b2;
        font-weight: 400;
        font-family: SourceHanSansCN-Medium;
      }
    }
  }

  .first-person {
    border-radius: 12px;
    display: flex;
    justify-content: center;
    margin: 0 25px;
    width: 165px;

    .first-box {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
      border-radius: 8px;
      margin-bottom: 24px;
      cursor: pointer;
      height: 105%;

      .first-img {
        overflow: visible !important;
        font-size: 14px;
        color: #101010;
        font-weight: 400;
        margin-bottom: 8px;
        height: 120px;
        position: relative;
      }

      .first-schoolName {
        overflow: visible !important;
        font-size: 14px;
        color: #a5a7b2;
        font-weight: 400;
        height: 36px;
        line-height: 36px;
      }

      .first-total-score {
        overflow: visible !important;
        font-size: 14px;
        color: #333333;
        font-weight: 600;
        font-family: SourceHanSansCN-Medium;
      }
    }
  }

  .third-person {
    margin: 0 25px;
    width: 165px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;

    .third-box {
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
      border-radius: 8px;
      margin-bottom: 24px;
      cursor: pointer;
      margin-top: 50px;

      .third-img {
        overflow: visible !important;
        font-size: 14px;
        color: #101010;
        font-weight: 400;
        margin-bottom: 8px;
        height: 120px;
        position: relative;
      }

      .third-schoolName {
        overflow: visible !important;
        font-size: 14px;
        color: #a5a7b2;
        font-weight: 400;
        height: 36px;
        line-height: 36px;
      }

      .third-total-score {
        overflow: visible !important;
        font-size: 14px;
        color: #333333;
        font-weight: 600;
        font-family: SourceHanSansCN-Medium;
      }
    }
  }
}

.other-person {
  width: 100%;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .other-box {
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.09);
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-around;

    .other-img {
      display: flex;
      flex: 1;
      width: 100%;
      justify-content: flex-start;

      .other-img-box {
        overflow: visible !important;
        font-size: 14px;
        color: #101010;
        font-weight: 400;
        height: 30px;
        width: 100px;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-align: left;
        line-height: 30px;
        margin-right: 80px;
        color: #333;
        font-weight: bold;

        .other-userName {
          color: #333;
          font-weight: bold;
          margin: 0 15px;
          display: inline-block;
          width: 20px;
          text-align: center;
        }
      }
    }
  }
}

.other-schoolName {
  overflow: visible !important;
  font-size: 14px;
  color: #a5a7b2;
  font-weight: 400;
  height: 30px;
  line-height: 30px;
}

.other-endTime {
  overflow: visible !important;
  font-size: 14px;
  color: #a5a7b2;
  font-weight: 400;
  font-family: SourceHanSansCN-Regular;
  height: 30px;
  width: 120px;
  line-height: 30px;
  margin-right: 40px;
}

.other-total-score {
  overflow: visible !important;
  font-size: 14px;
  color: #333;
  font-weight: 600;
  font-family: SourceHanSansCN-Medium;
  height: 30px;
  width: 40px;
  line-height: 30px;
  margin-right: 50px;
}

.no-data {
  display: inline-block;
  padding: 42px 0;
}
</style>
